
import React, { Suspense, useState } from "react"
import { useTranslation } from "react-i18next";
import flag from '../assets/red-flag.svg'
import flagMob from '../assets/flag-mob.svg'
import { useDispatch } from "react-redux";
import { useDeleteMutation } from "../store/Apis/UserApi";
import { logout } from "../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";

export function DeleteAccount() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const [deleteAccount] = useDeleteMutation();
    const navigate = useNavigate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            // Form can be submitted
            deleteAccount(formData).unwrap().then(() => {
                dispatch(logout({}))
                navigate('/landing')
            })
                .catch(err => {
                    console.log(err)
                    if(err.data.error_code)
                    setErrors({ error:t("errors."+err.data.error_code)||err.data.error})
                    else
                    setErrors({ error:err.data.error})
                })
        } else {
            setErrors(validationErrors);
        }
    }
    const validateInput = (name: string, value: string) => {
        switch (name) {
            default:
                return null;
        }
    };

    const validateForm = () => {
        const validationErrors: any = {};
        for (const [name, value] of Object.entries(formData)) {
            const err = validateInput(name, value)
            if (err)
                validationErrors[name] = err;
        }
        return validationErrors;
    };

    const handleChange = (event: any) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setErrors({ ...errors, [event.target.name]: validateInput(event.target.name, event.target.value) });
    };

    return (
        <Suspense>
            <div className=" h-screen w-screen web:overflow-hidden">
                <div className="h-full w-full bg-orange flex flex-col web:visible phone:hidden">
                    <h1 className="w-[100%] text-center pt-20 text-7xl text-red font-bold font-Inter">{t('delete.delete_account')}</h1>
                    <div className="w-[90%] flex flex-row  mt-28">
                        <form onSubmit={handleSubmit} className="w-[50%] ms-10  flex flex-col items-end">
                            <input type="email" className="w-full h-16 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-6" name="email" value={formData.email} onChange={handleChange} placeholder={t('login.email') || "email"} required></input>
                            <input type="password" className="w-full h-16 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-6 mt-4" placeholder={t('login.pwd') || "password"} name="password" value={formData.password} onChange={handleChange} required></input>
                            {(Object.entries(errors).length > 0) && <ul className="w-full flex flex-col items-center justify-center">
                                {Object.entries(errors).map(([key, value], index) => {
                                    if (value)
                                        return (<li key={index} className="text-red text-sm font-bold w-[98%] " >{value.toString()}</li>)
                                    else
                                        return (null);
                                })}
                            </ul>}
                            <input className="w-[25%] h-14 bg-light-blue p-2 mt-8 border-2 font-Inter font-bold border-black shadow-lg" type="submit" value={t('delete.delete_account')||"delete"}/>
                        </form>
                        <div className="grow flex flex-col h-full items-end justify-end">
                            <img alt="flag" className="grow w-[90%]" src={flag}></img>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="relative flex flex-col justify-start w-screen h-screen bg-red items-center web:hidden phone:visible ">
                    <h1 className="w-[74%] mt-16 text-5xl text-landing-yellow font-bold font-Inter z-30">{t('delete.delete_account')}</h1>
                    <div className="  flex flex-col w-full items-center z-30">
                        <input type="email" className="w-[90%] h-16 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6" placeholder={t('login.email') || "email"} name="email" value={formData.email} onChange={handleChange} ></input>
                        <input type="password" className="w-[90%] h-16 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4" placeholder={t('login.pwd') || "password"} name="password" value={formData.password} onChange={handleChange} ></input>
                    </div>
                    <input className="w-[70%] h-14 bg-light-blue p-2 mt-8 border-2 font-Inter font-bold border-black shadow-lg mb-4 z-30" type="submit" value={t('delete.delete_account')||"delete"}/>
                    <img alt="flag orange" className="absolute top-[25%] z-10" src={flagMob}></img>
                </form>
            </div >
        </Suspense >
    )
}