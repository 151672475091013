import React, { Suspense, useEffect } from "react";
import { About } from "../components/LandingScreen/About";
import { Footer } from "../components/LandingScreen/Footer";
import { HeroBanner } from "../components/LandingScreen/HeroBanner";
import { HowToPlay } from "../components/LandingScreen/HowToPlay";
import { PurshaseBanner } from "../components/LandingScreen/PurshaseBanner";
import { VideoBanner } from "../components/LandingScreen/VideoBanner";
import { FeedbackModal } from "../components/Authentication/FeedbackModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setShowFeedBack } from "../store/slices/MainScreenSlice";
import { setRefresh } from "../store/slices/UserSlice";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { TermsModal } from "../components/Authentication/TermsModal";

export function LandingScreenPage(props: { setShowModal?: any, show?: boolean }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showModal = useSelector(
    (state: RootState) => state.mainScreen.showFeedBack
  );
  const refresh = useSelector((state: RootState) => state.user.refresh);

  useEffect(() => {
    if (refresh) {
      dispatch(setRefresh({}));
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <Suspense>
      <Helmet>
        <title>{t("helmet.home_title")}</title>
        <meta
          name="description"
          content={
            t("helmet.home_desc") || "Propaganda is an online funny board game"
          }
        ></meta>
      </Helmet>
      <div className="flex flex-col w-full bg-landing-yellow">
        {showModal && (
          <FeedbackModal
            setShowModal={() => dispatch(setShowFeedBack(false))}
          ></FeedbackModal>
        )}
        {props?.show && <TermsModal setShowModal={()=>{props?.setShowModal(false)}} params={""}/>}
        <HeroBanner></HeroBanner>
        <HowToPlay></HowToPlay>
        <PurshaseBanner></PurshaseBanner>
        <VideoBanner></VideoBanner>
        <About></About>
        <Footer></Footer>
      </div>
    </Suspense>
  );
}
