import Cookies from 'js-cookie';
import { Api } from './Api';


export const UserApi = Api.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (data) => ({
                url: "auth/register",
                method: 'POST',
                body: data
            })
        }),
        addBillingInfo: builder.mutation({
            query: (data) => ({
                url: "auth/billing",
                headers: {
                    token: Cookies.get('token')
                },
                method: 'POST',
                body: data
            })
        }),
        login: builder.mutation({
            query: (data) => ({
                url: "auth/login",
                method: 'POST',
                body: data
            })
        }),
        delete: builder.mutation({
            query: (data) => ({
                url: "auth/delete-account",
                method: 'POST',
                body: data
            })
        }),
        forgotPwd: builder.mutation({
            query: (data) => ({
                url: "auth/forgot-password",
                method: 'POST',
                body: data
            })
        }),
        resetPwd: builder.mutation({
            query: (data) => ({
                url: "auth/reset-password",
                method: 'POST',
                body: data
            })
        }),
        getBlogs: builder.mutation({
            query: (data:{page:number,limit:number,search:string,tags:string[]}) => ({
                url: `blog?page=${data.page}&limit=${data.limit}&search=${data.search}&tags=${data.tags}`,
                method: 'GET'
            })
        }),
        getBlogById: builder.mutation({
            query: (data:{id:string}) => ({
                url: `blog/byId/${data.id}`,
                method: 'GET'
            })
        }),
        getTags: builder.mutation({
            query: () => ({
                url: `blog/tags`,
                method: 'GET'
            })
        })
    }),
})

export const {
    useRegisterMutation,
    useLoginMutation,
    useForgotPwdMutation,
    useResetPwdMutation,
    useAddBillingInfoMutation,
    useGetBlogsMutation,
    useGetTagsMutation,
    useGetBlogByIdMutation,
    useDeleteMutation
} = UserApi