import React from "react";
import parse from "html-react-parser";
import { contentAr,contentEn } from "../../utils/privacyPolicy";

export function PrivacyPolicy() {
  return (
    <div className="w-full h-full p-12">
          {parse(contentEn)}
          {parse(contentAr)}
    </div>
  );
}
